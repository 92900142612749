<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <edit-tab
        :item-data="userData"
        :lista-empresas="listaEmpresas"
        class="mt-1"
    />
  </component>
</template>

<script>
import { BAlert, BCard, BLink, BTab, BTabs, } from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import leadStoreModule from '../leadStoreModule'
import EditTab from './EditTab.vue'
import store from '@/store'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    EditTab,
  },
  setup() {
    const userData = ref({})

    const ITEM_APP_STORE_MODULE_NAME = 'app-leads'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, leadStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    })

    const listaEmpresas = ref([])
    store.dispatch('app-leads/fetchEmpresas')
        .then(response => {
          listaEmpresas.value = response.data
        })

    store.dispatch('app-leads/show', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    return {
      userData,
      listaEmpresas,
    }
  },
}
</script>
